<template>
  <template v-if="'{}' !== JSON.stringify(formItems)">
    <search-form
      ref="searchFormRef"
      :form-items="formItems"
      :fetch-data="refreshData"
      :download="download"
      :default-page-size="defaultPageSize"
      @reset="resetSort"
    >
      <template #buttons>
        <slot name="buttons"></slot>
      </template>
    </search-form>
  </template>
  <slot name="total"></slot>
  <el-row v-if="initTotal" class="total-data">
    <el-space :size="20">
      <span v-for="list in totalData" :key="list.name" class="total-item">
        {{ list.name }}：
        <template v-if="list.value instanceof Object">
          <span class="bolder">{{ list.value.value }}</span>
          <el-tag v-if="list.value.rate < 0" type="warning" effect="plain" size="small" style="border: 0 !important">
            <i class="el-icon-bottom"></i>{{ list.value.rate }}%
          </el-tag>
          <el-tag v-else type="success" effect="plain" size="small" style="border: 0 !important">
            <i class="el-icon-top"></i>{{ list.value.rate }}%
          </el-tag>
        </template>
        <template v-else>
          <span class="bolder"> {{ list.value }}</span>
        </template>
      </span>
    </el-space>
  </el-row>
  <el-row class="mb-10" v-if="'table' === type" style="width: 100%">
    <init-table
      ref="tableRef"
      :table-type="tableType"
      :table-fields="fields"
      :table-data="data"
      :format-status="formatStatus"
      :span-method="mergeCell"
      :show-summary="showSummary"
      :summary-total="summaryTotal"
      :row-key="rowKey"
      :table-selectable="tableSelectable"
      @selection-change="$emit('selection-change', $event)"
      @sort-change="handleSortChange($event)"
    >
      <template #operations>
        <slot name="operations"></slot>
      </template>
      <slot name="content"></slot>
    </init-table>
  </el-row>
  <el-row v-else class="mb-10">
    <template v-if="data.length">
      <slot name="content" :data="data"></slot>
    </template>
    <el-empty v-else description="暂无数据" class="el-col-24"></el-empty>
  </el-row>
  <el-row class="mb-10" :justify="selectedData ? 'space-between' : 'end'">
    <slot name="selected" :refData="tableRef"></slot>
    <el-pagination
      v-if="pageData"
      v-model:page-size="pageData.pagesize"
      v-model:current-page="pageData.page"
      :total="pageData.total"
      :page-sizes="[5, 10, 20, 40, 60, 80, 100, 200, 300, 400]"
      @size-change="pageChange"
      @current-change="pageChange"
      layout="total, sizes, prev, pager, next, jumper"
      background
    />
  </el-row>
</template>

<script setup>
import SearchForm from '@/components/base/SearchForm'
import { onMounted, ref, defineProps, defineExpose } from 'vue'
import InitTable from '@/components/base/InitTable'

const props = defineProps({
  type: { default: 'table' },
  formItems: { type: Object },
  fetchData: { type: Function },
  initTotal: { type: Function },
  selectedData: { type: Function },
  fields: { default: [] },
  formatStatus: { type: Function },
  summaryTotal: { type: Function },
  showSummary: { default: false },
  mergeCell: { type: Function },
  download: { default: null },
  defaultPageSize: { default: 20 },
  tableType: { default: '' },
  rowKey: { default: '' },
  tableSelectable: { type: Function },
})

const searchFormRef = ref(null)
const tableRef = ref(null)
const data = ref([])
const pageData = ref({ page: 1, pagesize: props.defaultPageSize, total: 0 })
const totalData = ref(props.initTotal ? props.initTotal() : {})

const refreshData = async (params, withPage = false, staySelected = false) => {
  if (!searchFormRef || !searchFormRef.value) return null
  let valid = await searchFormRef.value.formRef.validate()
  if (!valid) return
  if (props.fetchData) {
    if (!staySelected && tableRef.value && tableRef.value.tableRef && tableRef.value.tableRef.clearSelection) {
      tableRef.value.tableRef.clearSelection()
    }
    let res = await props.fetchData(
      withPage ? Object.assign(params, pageData.value) : Object.assign(pageData.value, params)
    )
    data.value = res.data
    pageData.value = res.page
  }
}

const pageChange = () => {
  if (!searchFormRef || !searchFormRef.value) {
    props.fetchData({ page: pageData.value.page, pagesize: pageData.value.pagesize }).then((res) => {
      data.value = res.data
      pageData.value = res.page
    })
  } else {
    refreshData(searchFormRef.value.getSearchForm(), true, true)
  }
}

const getSearchForm = () => {
  return searchFormRef.value.getSearchForm()
}

const getTableData = () => {
  return data.value
}

onMounted(() => {
  if ('{}' === JSON.stringify(props.formItems) && props.fetchData) {
    props
      .fetchData({ page: 1, pagesize: props.defaultPageSize })
      .then((res) => {
        data.value = res.data
        pageData.value = res.page
      })
      .finally(() => {})
  }
})

const handleSortChange = (params) => {
  refreshData(Object.assign(searchFormRef.value.getSearchForm(), params))
}

const resetSort = () => {
  if (tableRef.value && tableRef.value.resetSort) tableRef.value.resetSort()
}

defineExpose({ getSearchForm, getTableData, refreshData, tableRef })
</script>
<style scoped>
.total-data {
  color: var(--el-text-color-regular);
}
</style>
