<template>
  <el-table ref="tableRef" :data="tableData" :show-summary="showSummary" :summary-method="summaryTotal"
    :cell-class-name="formatStatus" :span-method="mergeCell" class="data-table" :row-key="rowKey"
    :header-cell-style="{ background: 'var(--el-table-row-hover-background-color)' }"
    :header-cell-class-name="handleHeadAddClass" @selection-change="$emit('selection-change', $event)"
    @sort-change="handleSortChange($event)">
    <template v-if="tableType">
      <el-table-column v-if="tableSelectable" :type="tableType" reserve-selection :selectable="tableSelectable"
        width="55" />
      <el-table-column v-else :type="tableType" reserve-selection width="55" />
    </template>

    <template v-for="(field, index) in tableFields" :key="field.id">
      <el-table-column v-if="field.id" header-align="center" :prop="field.id"
        :sortable="field.sortable ? field.sortable : false" :sort-orders="['ascending', 'descending', 'none']"
        :align="field.align ? field.align : 'center'" show-overflow-tooltip :min-width="field.width ? field.width : 150"
        :fixed="field.fixed ? field.fixed : index === 0 ? 'left' : null">
        <template #header>
          {{ field.label }}
          <el-tooltip v-if="field.tips" class="item" effect="dark" :content="field.tips" placement="top">
            <el-icon>
              <info-filled></info-filled>
            </el-icon>
          </el-tooltip>
        </template>
        <template #default="scope">
          <template v-if="typeof (field.content
              ? field.content(scope.row[field.id])
              : scope.row[field.id]) === 'object'
            ">
            <el-space>
              <el-tag v-for="fieldData in field.content
                ? field.content(scope.row[field.id])
                : scope.row[field.id]" :key="fieldData" size="small">
                {{ fieldData }}
              </el-tag>
            </el-space>
          </template>
          <template v-else-if="field.format">
            {{ field.format(scope.row[field.id], scope.row) }}
          </template>
          <template v-else>{{ scope.row[field.id] }}</template>
        </template>
      </el-table-column>
      <el-table-column v-else header-align="center" :label="field.label" :align="field.align ? field.align : 'center'"
        show-overflow-tooltip :min-width="field.width ? field.width : 150"
        :fixed="field.fixed ? field.fixed : index === 0 ? 'left' : null">
        <el-table-column v-for="item in field.items" :key="item.id" header-align="center" :prop="item.id"
          :label="item.label" :align="item.align ? item.align : 'center'" show-overflow-tooltip
          :min-width="item.width ? item.width : 150" :fixed="item.fixed ? item.fixed : index === 0 ? 'left' : null">
          <template #header>
            {{ item.label }}
            <el-tooltip v-if="item.tips" class="item" effect="dark" :content="item.tips" placement="top">
              <el-icon>
                <info-filled></info-filled>
              </el-icon>
            </el-tooltip>
          </template>
          <template #default="scope">
            {{ scope.row[item.id] == undefined ? '--' : scope.row[item.id] }}
          </template>
        </el-table-column>
      </el-table-column>
    </template>
    <slot name="operations"></slot>
  </el-table>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { InfoFilled } from "@element-plus/icons-vue";

defineProps({
  tableData: { type: Array },
  tableFields: { default: [] },
  formatStatus: { type: Function },
  summaryTotal: { type: Function },
  showSummary: { default: false },
  mergeCell: { type: Function },
  tableType: { default: "" },
  sortBy: { default: null },
  rowKey: { default: '' },
  tableSelectable: { type: Function }
});
let emit = defineEmits(['sort-change'])

const handleHeadAddClass = ({ column }) => {
  if (sortField.value && sortField.value[column.property]) {
    column.order = sortField.value[column.property]
    return
  }

  column.order = ''
}

const tableRef = ref(null)
let orderBys = ref({});
let sortField = ref({})
const handleSortChange = ({ column, order, prop }) => {
  if (column === null) {
    return null
  }

  if (!order || (sortField.value && sortField.value[prop] === order)) {
    sortField.value[prop] = null
  } else {
    sortField.value[prop] = order
  }

  handleSortFieldChange()
}

const handleSortFieldChange = () => {
  let direction = ''
  for (let i in sortField.value) {
    if (sortField.value[i] === 'ascending') {
      direction = 2
    } else if (sortField.value[i] === 'descending') {
      direction = 1
    } else {
      direction = 0
    }

    orderBys.value[i] = direction
  }
  emit('sort-change', orderBys.value)
}

const resetSort = () => {
  sortField.value = {}
  orderBys.value = {}
  tableRef.value.clearSort()
}
defineExpose({ tableRef, resetSort })
</script>
<style>
.el-table__fixed-right::before,
.el-table__fixed::before {
  display: none !important;
}
</style>